<template>
    <div class="details-card">
        <div class="details-text">
            <div class="attribution-container">
                <a
                    :href="
                        'https://rs-review.lincsproject.ca/resource/?uri=' +
                        person.object
                    "
                    class="wrap-text details-card-link"
                    target="_blank"
                    >{{ person.objectLabel ?? person.object }}
                    <img src="@/assets/external-link-blue.svg" />
                </a>
            </div>
            <h3 class="wrap-text">
                {{ convertDateStrings(person.birthDate) }} -
                {{ convertDateStrings(person.deathDate) }}
            </h3>
            <h4 class="wrap-text">
                {{ person.description }}
                <a
                    v-if="person.hasOwnProperty('wikidataAttribution')"
                    class="attribution-container"
                    :href="person.wikidataAttribution"
                    target="_blank"
                    :title="person.wikidataAttribution"
                >
                    <img
                        class="attribution-image"
                        src="@/assets/wikidata.png"
                    />
                </a>
            </h4>
        </div>
        <div class="details-image">
            <img
                :src="
                    person.image ??
                    'https://plugin.lincsproject.ca/cidoc-images/person.svg'
                "
            />
        </div>
    </div>
</template>
<script lang="ts">
import { Vue, Options } from "vue-class-component";
@Options({
    props: {
        person: Object,
    },
})
export default class PersonDetailsCard extends Vue {
    person!: any;

    convertDateStrings(date: string) {
        if (date != null) {
            return date.replaceAll("-", "/");
        } else {
            return "Unavailable";
        }
    }
}
</script>
<style>
.details-card {
    display: flex;
    position: relative;
    width: auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-left: 12px;
}

.details-card:hover {
    border: 1px solid #ccc;
    background: #edf6f8;
}
.details-card.active {
    border: 1px solid #107386;
    background: #edf6f8;
}

.details-text {
    text-align: left;
    position: relative;
    flex: 1;
    padding-right: 10px;
    width: calc(100% - 80px);
    padding-top: 12px;
    padding-bottom: 12px;
}

.details-image {
    position: relative;
    display: flex;
    width: 75px;
    height: 100%;
}
.details-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.details-text h3 {
    position: relative;
    margin: 5px 0 5px 0;
    color: #5e6364;
    font-size: 14px;
    font-weight: 400;
}
.details-text h4 {
    position: relative;
    margin: 5px 0 0 0;
    color: #333333;
    font-size: 16px;
    font-weight: 400;
}
</style>
