<template>
    <div>
        <select
            class="type-filter width-100"
            v-model="dateFilterListView"
            :aria-label="`Sort ${connectedEntityTitle}`"
        >
            <option value="all">{{ $t("EntityList.sort_by") }}:</option>
            <option value="asc">
                {{ $t("EntityList.alphabetical") }} (A to Z)
            </option>
            <option value="desc">
                {{ $t("EntityList.alphabetical") }} (Z to A)
            </option>
        </select>
        <div class="entity-details-scrollbox">
            <div
                class="entity-details-wrapper"
                v-for="(entity, index) in sortedEntitiesInListView"
                :key="index"
            >
                <EntityResourceCard
                    v-if="!annotationsView"
                    :data="entity"
                    :index="index"
                    :currentExpandedIndex="expandedEntityIndex"
                    v-on:update="
                        (values: any) =>
                            ($parent as any).handleToggleOfDetails(
                                values.index,
                                values.mainEntity,
                            )
                    "
                />
                <AnnotationCard
                    v-else
                    :label="entity.annotationLabel"
                    :target="getWebReferenceTarget(entity) ?? ''"
                    :dataset="entity.graph ?? ''"
                    :index="index"
                    v-on:update="
                        (annotationInfo: any) =>
                            selectWebReference(annotationInfo.index, annotationInfo.image)
                    "
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import EntityResourceCard from "../components/EntityResourceCard.vue";
import AnnotationCard from "../components/AnnotationCard.vue";

@Options({
    props: {
        connectedEntityTitle: String,
        categoryToDisplay: Object,
        annotationsView: Boolean,
        expandedEntityIndex: Number,
    },
    components: {
        EntityResourceCard,
        AnnotationCard,
    },
})
export default class EntityList extends Vue {
    connectedEntityTitle!: string;
    categoryToDisplay!: any;
    annotationsView!: boolean;
    expandedEntityIndex!: number;

    dateFilterListView = "all";

    // Entities sorted by alphabetic order
    get sortedEntitiesInListView() {
        if (
            this.dateFilterListView === "asc" ||
            this.dateFilterListView === "desc"
        ) {
            if (this.categoryToDisplay) {
                const sortedEntities = [...this.categoryToDisplay];
                if (!this.annotationsView) {
                    this.sortEntitiesByString(
                        sortedEntities,
                        "resourceLabel",
                        this.dateFilterListView,
                    );
                } else {
                    this.sortEntitiesByString(
                        sortedEntities,
                        "annotationLabel",
                        this.dateFilterListView,
                    );
                }
                return sortedEntities;
            }
        }
        return this.categoryToDisplay;
    }

    // Sort the list view of the connected entities alphabetically
    sortEntitiesByString(arr: any[], property: string, order: string) {
        if (order === "asc") {
            return arr.sort((a, b) => {
                const textA = a[property]?.toLowerCase();
                const textB = b[property]?.toLowerCase();
                return textA?.localeCompare(textB);
            });
        } else if (order === "desc") {
            return arr.sort((a, b) => {
                const textA = a[property]?.toLowerCase();
                const textB = b[property]?.toLowerCase();
                return textB?.localeCompare(textA);
            });
        } else {
            return arr;
        }
    }

    // Parse an annotation and return the target
    getWebReferenceTarget(annotation: any) {
        if (annotation.digitalObjects.length == 0) {
            return "";
        } else {
            return annotation.digitalObjects[0].uri;
        }
    }

    selectWebReference(index: any, image:any) {
        (this.$parent as any).annotationIconMap[
            this.sortedEntitiesInListView[index]
        ] = image;

        (this.$parent as any).displayWebReference(
            this.sortedEntitiesInListView[index]
        );
    }
}
</script>
<style>
.entity-details-scrollbox {
    overflow: auto;
    height: calc(100vh - 414px);
}
</style>
