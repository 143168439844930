<template>
    <div
        :class="{
            'site-title-text sub-title': true,
            'main-entity': firstEntity,
            'sub-child':
                firstEntity || (index < 2 && !(index == 1 && annotationsView)),
        }"
        :style="childEntityStyles(index)"
    >
        <!--Arrow Image-->
        <img
            v-if="!firstEntity"
            class="img-fluid large-icon"
            src="@/assets/enter-icon.svg"
            alt="Indent child"
        />
        <div class="stitle-text profile-avtar">
            <div class="avtar-img">
                <!--Entity Image-->
                <img
                    class="img-fluid"
                    :src="entity.mainEntityInfo[0].image"
                    :alt="
                        entity.mainEntityInfo[0].resourceLabel ??
                        entity.mainEntityInfo[0].resource
                    "
                />
            </div>
            <div class="profile-details">
                <!--Entity Title-->
                <h3
                    :class="{
                        'two-line-ellipsis': true,
                        'sub-child':
                            firstEntity ||
                            (index < 2 && !(index == 1 && annotationsView)),
                        'auto-width': !firstEntity && index > 1,
                    }"
                >
                    {{
                        entity.mainEntityInfo[0].resourceLabel
                            ? entity.mainEntityInfo[0].resourceLabel
                            : entity.mainEntityInfo[0].resource
                    }}
                </h3>
                
                <!--Entity Type-->
                <span
                    :class="{
                        'sub-child':
                            firstEntity ||
                            (index < 2 && !(index == 1 && annotationsView)),
                    }"
                    >{{ entity.mainEntityInfo[0].typeLabel }}</span
                >
            </div>
        </div>
        <div
            class="icon-btn-group"
            v-show="
                firstEntity || (index < 2 && !(index == 1 && annotationsView))
            "
        >
            <!--Return to previous entity button-->
            <a
                v-show="parsedEntityInfo.length > index + 1"
                class="return-previous-entity"
                href="javascript:void(0)"
                v-on:click="
                    returnToPreviousEntity(index);
                    resetLastEntity();
                "
                >Return</a
            >
            <!--Toggle details dropdown button-->
            <a
                href="javascript:void(0)"
                role="button"
                aria-label="Toggle main entity details"
                title="Toggle main entity details"
                :class="{
                    'panel-button icon-btn': true,
                    'border-btn': showDetailsIndex == index,
                }"
                v-on:click="($parent as any).handleToggleOfDetails(index, true)"
            >
                Details
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        firstEntity: Boolean,
        entity: Object,
        index: Number,
        parsedEntityInfo: Array,
        showDetailsIndex: Number,
        annotationsView: Boolean,
    },
})
export default class EntityBanner extends Vue {
    firstEntity!: boolean;
    entity!: any;
    index!: number;
    parsedEntityInfo!: any;
    showDetailsIndex!: number;
    annotationsView!: boolean;

    // To go back to any previous entity
    returnToPreviousEntity(index: number) {
        const indexDiff = this.parsedEntityInfo.length - index - 1;
        (this.$parent as any).parsedEntityInfo.splice(index + 1, indexDiff);
        window.parent.postMessage(
            { type: "entity-traversal-back", amount: indexDiff },
            "*",
        );
    }

    // Reset the list/timeline panel while going to previous entity
    resetLastEntity() {
        (this.$parent as any).listView = true;
        (this.$parent as any).timelineView = false;
        (this.$parent as any).entitiesByDate = {};
    }

    // Add styling to the child entities
    childEntityStyles(index: number) {
        if (this.firstEntity) {
            return {};
        }

        const paddingLeft = 5 * (index + 1) + "px";
        const borderLeft =
            index === this.parsedEntityInfo[0].children?.length - 1
                ? "4px solid #107386"
                : "";
        return {
            paddingLeft,
            borderLeft,
        };
    }
}
</script>
<style>
.icon-btn-group .panel-button {
    margin-left: 5px;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 10px;
}

.icon-btn-group {
    display: flex;
    align-items: center;
}

.icon-btn.border-btn {
    border-color: #87b9c3;
}

.icon-btn.border-btn img {
    width: 20px;
}

.site-title-text {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    margin-top: 40px;
}

.site-title-text:hover .return-previous-entity {
    display: inline-block;
    text-decoration: none;
}

.site-title-text.sub-title.sub-child {
    background: #083943;
    align-items: flex-end;
    border-radius: 4px;
}

.site-title-text.sub-title.sub-child.main-entity {
    height: 60px;
}

.profile-avtar {
    position: relative;
    display: flex;
    align-items: center;
}

.profile-avtar .avtar-img {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    margin-left: 5px;
}

.profile-avtar .avtar-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.stitle-text {
    position: relative;
    flex: 1;
    display: flex;
}

.stitle-text span {
    font-size: 14px;
    display: block;
    color: #ffffff;
}

.stitle-text h3 {
    font-size: 16px;
    display: inline-block;
    color: #ffffff;
    margin: 5px 0 0 0;
    font-weight: 600;
}

.sub-title .stitle-text span {
    color: #525455;
    font-weight: 300;
}

.sub-title .stitle-text h3 {
    color: #083943;
}

.sub-title {
    width: auto;
    position: relative;
    margin-top: 0px;
    padding: 8px 8px 8px 12px;
    margin-bottom: 0;
    background-color: #e7f1f3;
    overflow: hidden;
}

.sub-title.active {
    padding-left: 15px;
}

.sub-title.active:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background: #107386;
    left: 0;
    top: 0;
}

.return-previous-entity {
    display: none;
    text-decoration: none;
    color: white;
    background-color: #107386;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.sub-child {
    width: auto;
    color: #fff !important;
}

.auto-width {
    width: auto !important;
}

.two-line-ellipsis {
    display: -webkit-box !important;
    width: 100% !important;
    max-height: 40px;
    margin: 0 auto;
    line-height: 18px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
