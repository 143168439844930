import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/enter-icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "img-fluid large-icon",
  src: _imports_0,
  alt: "Indent child"
}
const _hoisted_2 = { class: "stitle-text profile-avtar" }
const _hoisted_3 = { class: "avtar-img" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "profile-details" }
const _hoisted_6 = { class: "icon-btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            'site-title-text sub-title': true,
            'main-entity': _ctx.firstEntity,
            'sub-child':
                _ctx.firstEntity || (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
        }),
    style: _normalizeStyle(_ctx.childEntityStyles(_ctx.index))
  }, [
    (!_ctx.firstEntity)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "img-fluid",
          src: _ctx.entity.mainEntityInfo[0].image,
          alt: 
                        _ctx.entity.mainEntityInfo[0].resourceLabel ??
                        _ctx.entity.mainEntityInfo[0].resource
                    
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h3", {
          class: _normalizeClass({
                        'two-line-ellipsis': true,
                        'sub-child':
                            _ctx.firstEntity ||
                            (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
                        'auto-width': !_ctx.firstEntity && _ctx.index > 1,
                    })
        }, _toDisplayString(_ctx.entity.mainEntityInfo[0].resourceLabel
                            ? _ctx.entity.mainEntityInfo[0].resourceLabel
                            : _ctx.entity.mainEntityInfo[0].resource), 3),
        _createElementVNode("span", {
          class: _normalizeClass({
                        'sub-child':
                            _ctx.firstEntity ||
                            (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView)),
                    })
        }, _toDisplayString(_ctx.entity.mainEntityInfo[0].typeLabel), 3)
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("a", {
        class: "return-previous-entity",
        href: "javascript:void(0)",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
                    _ctx.returnToPreviousEntity(_ctx.index);
                    _ctx.resetLastEntity();
                })
      }, "Return", 512), [
        [_vShow, _ctx.parsedEntityInfo.length > _ctx.index + 1]
      ]),
      _createElementVNode("a", {
        href: "javascript:void(0)",
        role: "button",
        "aria-label": "Toggle main entity details",
        title: "Toggle main entity details",
        class: _normalizeClass({
                    'panel-button icon-btn': true,
                    'border-btn': _ctx.showDetailsIndex == _ctx.index,
                }),
        onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$parent as any).handleToggleOfDetails(_ctx.index, true)))
      }, " Details ", 2)
    ], 512), [
      [_vShow, 
                _ctx.firstEntity || (_ctx.index < 2 && !(_ctx.index == 1 && _ctx.annotationsView))
            ]
    ])
  ], 6))
}