<template>
    <SidePanelHeader></SidePanelHeader>
    <div class="loader-block-info" v-show="loading">
        <div class="spinner" id="loading-bar-spinner">
            <div class="spinner-icon"></div>
        </div>
    </div>
    <div class="site-section" v-if="!loading && !viewMoreView">
        <div class="header-btn-block" v-on:click="displayEntitySelect">
            <a href="javascript:void(0)" class="back-btn">
                <img src="@/assets/arrow-left.svg" alt="Back Button" />
                {{ $t("EntityInfoView.back_to_results") }}
            </a>
        </div>
        <div class="profile-section">
            <div v-for="(entity, index) in parsedEntityInfo" :key="index" class="profile-section-profile">
                <!--Entity Panel-->
                <EntityBanner
                    :firstEntity="index == 0"
                    :entity="entity"
                    :index="index"
                    :parsedEntityInfo="parsedEntityInfo"
                    :showDetailsIndex="showDetailsIndex"
                    :annotationsView="annotationsView"
                />

                <!--Entity Details Dropdown Panel-->
                <EntityDetailsDropdown
                    v-if="showDetailsIndex === index"
                    :data="entity"
                    v-on:update="
                        viewMoreView = true;
                        viewMoreEntity = entity;
                    "
                />
            </div>
        </div>

        <div
            v-if="
                (!displayCategorySelection && uriList.length != 1) ||
                (annotationsView && parsedEntityInfo.length != 1)
            "
            class="header-btn-block"
            v-on:click="toPreviousConnectedEntity"
        >
            <a href="javascript:void(0)" class="back-btn">
                <img src="@/assets/arrow-left.svg" alt="Back Button" />
                {{ $t("EntityInfoView.back") }}
            </a>
        </div>

        <ViewMoreDetailsView
            v-if="
                parsedEntityInfo.length >= 3 ||
                (annotationsView && parsedEntityInfo.length == 2)
            "
            :data="parsedEntityInfo[parsedEntityInfo.length - 1]"
            :isAnnotationView="annotationsView"
            :isTimeline="parsedEntityInfo[parsedEntityInfo.length - 2].connectedEntities.length > 1"
            hideBackButton
            isLastEntity
        >
        </ViewMoreDetailsView>

        <ThirdLevelTimeline
            v-if="parsedEntityInfo.length >= 3 && parsedEntityInfo[parsedEntityInfo.length - 2].connectedEntities.length > 1"
            :eventList="
                parsedEntityInfo[parsedEntityInfo.length - 2].connectedEntities
            "
            :currentEvent="
                parsedEntityInfo[parsedEntityInfo.length - 1].mainEntityInfo[0]
            "
            v-on:update="parsedEntityInfo.pop()"
        >
        </ThirdLevelTimeline>

        <!--Category Selection-->
        <CategorySelection
            v-show="!annotationsView && displayCategorySelection"
            :entityCategories="Object.keys(entitiesByCategory['Categories'])"
        />

        <!--Connected Entities-->
        <div
            class="entity-detail-section"
            v-show="
                (annotationsView && parsedEntityInfo.length != 2) ||
                (!displayCategorySelection &&
                    !viewMoreView &&
                    parsedEntityInfo.length != 3 &&
                    !annotationsView)
            "
        >
            <div
                class="header-btn-block category-back-btn"
                v-on:click="
                    displayCategorySelection = true;
                    selectedCategory = 'Selection';
                    annotationsView = false;
                "
            >
                <a
                    href="javascript:void(0)"
                    class="back-btn"
                    v-if="uriList.length == 1 && loading == false"
                >
                    <img src="@/assets/arrow-left.svg" alt="Back Button" />
                    {{ $t("EntityInfoView.back_to_categories") }}
                </a>
            </div>
            <div class="categories-block">
                <div class="entity-title sub-titles connected-title">
                    <h3>{{ connectedEntityTitle }}</h3>
                    <EntityViewSelector
                        v-show="uriList.length === 2"
                        v-on:update="
                            (update: any) => {
                                timelineView =
                                    update.timelineView != null
                                        ? update.timelineView
                                        : timelineView;
                                listView =
                                    update.listView != null
                                        ? update.listView
                                        : listView;
                            }
                        "
                    />
                </div>

                <EntityList
                    v-show="listView"
                    :connectedEntityTitle="connectedEntityTitle"
                    :categoryToDisplay="categoryToDisplay"
                    :annotationsView="annotationsView"
                    :expandedEntityIndex="expandedEntityIndex"
                />

                <EntityTimeline
                    v-show="timelineView"
                    :parsedEntityInfo="parsedEntityInfo"
                    :expandedEntityIndex="expandedEntityIndex"
                />
            </div>
        </div>
    </div>

    <!--View More Details View-->
    <ViewMoreDetailsView
        v-if="viewMoreView"
        :data="viewMoreEntity"
        v-on:update="viewMoreView = !viewMoreEntity"
    >
    </ViewMoreDetailsView>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { getEntityTypeName } from "../helper/EntityTypeHelper";
import EntityResourceCard from "../components/EntityResourceCard.vue";
import ViewMoreDetailsView from "../components/ViewMoreDetailsView.vue";
import SidePanelHeader from "../components/SidePanelHeader.vue";
import ThirdLevelTimeline from "../components/ThirdLevelTimeline.vue";
import CategorySelection from "../components/CategorySelection.vue";
import EntityViewSelector from "../components/EntityViewSelector.vue";
import EntityList from "../components/EntityList.vue";
import EntityTimeline from "../components/EntityTimeline.vue";
import EntityBanner from "../components/EntityBanner.vue";
import EntityDetailsDropdown from "../components/EntityDetailsDropdown.vue";
import AnnotationCard from "../components/AnnotationCard.vue";

@Options({
    components: {
        EntityResourceCard,
        ViewMoreDetailsView,
        SidePanelHeader,
        ThirdLevelTimeline,
        CategorySelection,
        EntityViewSelector,
        EntityList,
        EntityTimeline,
        EntityBanner,
        EntityDetailsDropdown,
        AnnotationCard,
    },
    watch: {
        $route() {
            const newUriList = JSON.parse(this.$route.params.uri as string);
            if (this.uriList.length > newUriList.length) {
                this.parsedEntityInfo.pop();
            }
            if (newUriList.length == 1) {
                this.displayEntitiesByCategory("Selection");
                this.showDetailsIndex = 0;
            } else if (newUriList.length == 2) {
                this.showDetailsIndex = 1;
            } else {
                this.showDetailsIndex = -1;
            }

            this.uriList = newUriList;
            this.loading = true;
        },
        selectedCategory() {
            this.showCategoryEntities();
        },
    },
})
export default class EntityInfoView extends Vue {
    uriList: Array<string> = [];
    loading = true;
    showDetailsIndex = 0;
    expandedEntityIndex = -1;
    viewMoreView = false;
    viewMoreEntity: any = null;
    displayCategorySelection = true;
    selectedCategory: any = null;
    entitiesByCategory: any = { Categories: [], All: [] };
    parsedEntityInfo: any = [];
    timelineView = false;
    listView = true;
    annotationsView = false;
    annotationIconMap: any = {};

    // Returns the displayed category
    get categoryToDisplay() {
        if (this.uriList.length == 1) {
            return this.entitiesByCategory["Categories"][this.selectedCategory];
        } else {
            return this.entitiesByCategory["All"];
        }
    }

    // Generates the title for the connected entities based on selected entity
    get connectedEntityTitle() {
        if (this.uriList.length == 1) {
            return "Connected " + this.selectedCategory;
        } else if (this.uriList.length == 2) {
            return "Connecting Entities";
        } else {
            return "Outbound Properties";
        }
    }

    mounted() {
        this.$i18n.locale = this.$route.params.locale as string;
        this.uriList = JSON.parse(this.$route.params.uri as string);
        window.addEventListener("message", this.handleMessage);
    }

    unmounted() {
        window.removeEventListener("message", this.handleMessage);
    }

    // MessageController for plugin
    handleMessage(event: any) {
        if (event.data.type == "update-entity-info") {
            this.parsedEntityInfo.forEach((entityInfo: any, index: number) => {
                if (
                    entityInfo.mainEntityInfo[0].resource ==
                    event.data.response.mainEntityInfo[0].resource
                ) {
                    this.parsedEntityInfo[index] = event.data.response;
                }
            });
            this.sortByCategories();
        } else if (
            event.data.type == "send-entity-info" ||
            event.data.type == "send-related-entities"
        ) {
            if (
                this.parsedEntityInfo.length == 0 ||
                event.data.response.mainEntityInfo[0].resource !=
                    this.parsedEntityInfo[this.parsedEntityInfo.length - 1]
                        .mainEntityInfo[0].resource
            ) {
                this.parsedEntityInfo.push(event.data.response);
            }
            this.sortByCategories();
            this.loading = false;
        } else if (event.data.type == "send-category") {
            this.displayEntitiesByCategory(event.data.category);
        } else if (event.data.type == "send-annotation") {
            this.displayWebReference(event.data.annotation);
        }
    }

    // Displays the entity select view
    displayEntitySelect() {
        window.parent.postMessage({ type: "show-entity-select" }, "*");
    }

    // To go back to the previous entity
    toPreviousConnectedEntity() {
        if (!this.annotationsView) {
            window.parent.postMessage(
                { type: "entity-traversal-back", amount: 1 },
                "*",
            );
        } else {
            this.parsedEntityInfo.pop();
            window.parent.postMessage({ type: "return-to-annotations" }, "*");
        }
    }

    // Toggles the dropdown open or closed
    handleToggleOfDetails(index: number, mainEntity: boolean) {
        if (mainEntity) {
            if (this.showDetailsIndex === index) {
                this.showDetailsIndex = -1;
            } else {
                this.showDetailsIndex = index;
            }
        } else {
            if (this.expandedEntityIndex === index) {
                this.expandedEntityIndex = 0.1;
            } else {
                this.expandedEntityIndex = index;
            }
        }
    }

    // To display the type of categories a selected entity has
    showCategoryEntities() {
        window.parent.postMessage(
            { type: "show-category-entities", category: this.selectedCategory },
            "*",
        );
    }

    // Displays the given category of entities
    displayEntitiesByCategory(category: string) {
        if (category != "Selection") {
            this.displayCategorySelection = false;

            this.annotationsView = category == "Web References";
            if (
                this.annotationsView &&
                this.selectedCategory == "Web References"
            ) {
                this.parsedEntityInfo.pop();
            }

            this.selectedCategory = category;
        } else {
            this.displayCategorySelection = true;
            this.annotationsView = false;
        }
    }

    // Displays a specific web reference
    displayWebReference(annotation: any) {
        const digitalObjectFormat = [];
        for (const digitalObject of annotation.digitalObjects) {
            digitalObjectFormat.push({
                predicateLabel: "Reference Location",
                object: digitalObject.uri,
                objectLabel:
                    digitalObject.label != "" ? digitalObject.label : null,
                objectIsURI: true,
                nonRsURI: true,
                image:
                    this.annotationIconMap[annotation.annotation] ??
                    "/cidoc-images/linguistic-object.svg",
            });
        }

        const formattedAnnotation = {
            mainEntityInfo: [
                {
                    image:
                        this.annotationIconMap[annotation.annotation] ??
                        "/cidoc-images/linguistic-object.svg",
                    resource: annotation.annotation,
                    resourceLabel: annotation.annotationLabel,
                    typeLabel:
                        "Annotation" +
                        (annotation.e55 != null ? "; " + annotation.e55 : ""),
                    outgoing: new Array<any>(),
                },
            ],
        };

        if (Object.prototype.hasOwnProperty.call(annotation, "quote")) {
            formattedAnnotation.mainEntityInfo[0].outgoing.push({
                predicateLabel: "Excerpt",
                object: annotation.quote,
                objectIsURI: false,
            });
        }
        formattedAnnotation.mainEntityInfo[0].outgoing.push(
            ...digitalObjectFormat,
        );

        window.parent.postMessage(
            {
                type: "display-annotation-info",
                annotation: formattedAnnotation,
            },
            "*",
        );
        this.parsedEntityInfo.push(formattedAnnotation);
    }

    // Fetch all the types available for an entity and sort them into categories
    sortByCategories() {
        const categorizedPredicates: any = {};
        categorizedPredicates["All"] = [];
        categorizedPredicates["Categories"] = {};

        for (const connectedEntity of this.parsedEntityInfo[
            this.parsedEntityInfo.length - 1
        ].connectedEntities) {
            if (connectedEntity) {
                const url = connectedEntity.type ?? "Other";
                const entityType = url.split("/")[url.split("/").length - 1];
                const entityTypeName = getEntityTypeName(
                    entityType,
                    this.$i18n.locale != "fr",
                );

                if (!(entityTypeName in categorizedPredicates["Categories"]))
                    categorizedPredicates["Categories"][entityTypeName] = [];

                categorizedPredicates["Categories"][entityTypeName].push(
                    connectedEntity,
                );
                categorizedPredicates["All"].push(connectedEntity);
            }
        }

        this.entitiesByCategory = categorizedPredicates;
    }
}
</script>
<style>
.loader-block-info {
    position: fixed;
    top: calc(50vh - 35px);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

#loading-bar-spinner.spinner {
    animation: loading-bar-spinner 1000ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 70px;
    height: 70px;
    border: solid 10px transparent;
    border-top-color: #083943 !important;
    border-left-color: #083943 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icon-btn {
    display: flex;
    background-color: #107386;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    transition: 0.3s all ease;
    border: 1px solid #107386;
    transition: 0.2s all ease;
}

.icon-btn:hover {
    background-color: #4e98a6;
    border-color: #4e98a6;
    transition: 0.3s all ease;
}

.icon-btn img {
    width: 14px;
    background-color: transparent;
}

.icon-btn img.large-icon {
    width: 18px;
}

.icon-btn.white-bg {
    background-color: white;
}

.icon-btn.white-bg:hover {
    background-color: #c2dbe0;
}

.icon-btn.white-bg:hover img {
    background-color: transparent;
}

.large-icon {
    width: 20px;
}

.site-section {
    overflow: auto;
    right: 0px;
    top: 0;
    width: auto;
    height: calc(100vh - 62px);
    background-color: #ffffff;
    border-left: 1px solid #dddddd;
}

.profile-section {
    position: relative;
}

.profile-section.light-bg {
    background-color: #e7f1f3;
}

.profile-section.light-bg .pro-details-box p {
    color: #333333;
    width: 100%;
}

.profile-section.light-bg .low-tone {
    background-color: #c2dbe0;
}

.profile-section.light-bg .pro-details-box label {
    color: #414343;
}

.profile-section-profile {
    border-bottom: 1px solid #ddd;
}

.profile-details {
    flex: 1;
    text-align: left;
    position: relative;
    border-radius: 4px;
}

.profile-details.profile-details-data {
    height: 0px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s all ease;
}

.profile-details.profile-details-data.active {
    height: 201px;
    overflow: scroll;
    visibility: visible;
    opacity: 1;
    transition: 0.3s all ease;
}

.low-tone {
    background-color: #0a4652;
}

.pro-details-box {
    padding: 7px 15px 7px;
}

.pro-details-box p {
    margin: 0;
    color: white;
    font-size: 14px;
}

.pro-details-box label {
    color: #d2d2d2;
    font-size: 14px;
    margin-bottom: 3px;
    display: block;
}

.entity-title {
    padding: 25px 20px 20px 15px;
    position: relative;
}

.entity-title h3 {
    font-size: 18px;
    margin: 0 0 0 0;
    font-weight: 500;
    color: #29313a;
}

.entity-title.sub-titles {
    padding: 10px 0px 15px 0px;
}

.entity-title.entity-category-title {
    text-align: left;
}

.entity-detail-section {
    text-align: left;
    position: relative;
}

.entity-details-wrapper {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;
}

.categories-block {
    padding: 0px 12px 0px;
}

.header-btn-block {
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 15px;
}

.header-btn-block.category-back-btn {
    margin-top: 16px;
    padding: 0;
}

.header-btn-block:hover {
    background-color: #edf6f8;
    transition: 0.2s ease all;
}

.entity-detail-section .category-back-btn .back-btn {
    padding: 16px;
    width: 100%;
    background-color: white;
    transition: 0.2s ease all;
}
.entity-detail-section .category-back-btn .back-btn:hover {
    background-color: #edf6f8;
    transition: 0.2s ease all;
}

.back-btn {
    font-size: 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #333333;
}

.back-btn img {
    margin-right: 10px;
}

.back-btn:hover {
    text-decoration: none;
}

.truncate-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.connected-title {
    padding: 10px 0px 15px 0px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.type-filter {
    width: calc(100% - 10px);
    height: 38px;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px;
    margin-bottom: 10px;
    border-color: #cccccc;
}

.width-100 {
    width: 100% !important;
}
</style>
