import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { value: "all" }
const _hoisted_3 = { value: "asc" }
const _hoisted_4 = { value: "desc" }
const _hoisted_5 = { class: "entity-details-scrollbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityResourceCard = _resolveComponent("EntityResourceCard")!
  const _component_AnnotationCard = _resolveComponent("AnnotationCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      class: "type-filter width-100",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilterListView) = $event)),
      "aria-label": `Sort ${_ctx.connectedEntityTitle}`
    }, [
      _createElementVNode("option", _hoisted_2, _toDisplayString(_ctx.$t("EntityList.sort_by")) + ":", 1),
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.$t("EntityList.alphabetical")) + " (A to Z) ", 1),
      _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.$t("EntityList.alphabetical")) + " (Z to A) ", 1)
    ], 8, _hoisted_1), [
      [_vModelSelect, _ctx.dateFilterListView]
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedEntitiesInListView, (entity, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "entity-details-wrapper",
          key: index
        }, [
          (!_ctx.annotationsView)
            ? (_openBlock(), _createBlock(_component_EntityResourceCard, {
                key: 0,
                data: entity,
                index: index,
                currentExpandedIndex: _ctx.expandedEntityIndex,
                onUpdate: 
                        (values) =>
                            (_ctx.$parent as any).handleToggleOfDetails(
                                values.index,
                                values.mainEntity,
                            )
                    
              }, null, 8, ["data", "index", "currentExpandedIndex", "onUpdate"]))
            : (_openBlock(), _createBlock(_component_AnnotationCard, {
                key: 1,
                label: entity.annotationLabel,
                target: _ctx.getWebReferenceTarget(entity) ?? '',
                dataset: entity.graph ?? '',
                index: index,
                onUpdate: 
                        (annotationInfo) =>
                            _ctx.selectWebReference(annotationInfo.index, annotationInfo.image)
                    
              }, null, 8, ["label", "target", "dataset", "index", "onUpdate"]))
        ]))
      }), 128))
    ])
  ]))
}