<template>
    <div class="rs-button-container">
        <div class="rs-button-copy">
            <div class="wrap-text rs-button-resource">{{ resource }}</div>
            <div
                class="rs-button-copy-icon rs-button-hover"
                title="Copy URI"
                role="button"
                tabindex="0"
                v-on:click="copyResource()"
            >
                <img class="img-fluid" src="@/assets/copy-icon.svg" alt="" />
            </div>
        </div>
        <a
            class="rs-button-rs rs-button-hover"
            :href="
                'https://rs-review.lincsproject.ca/resource/?uri=' + resource
            "
            target="_blank"
            >{{ $t("ResearchSpaceButton.view_resource_on") }}
            <img
                class="img-fluid"
                src="@/assets/external-link-line.svg"
                alt="External link"
        /></a>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        resource: String,
    },
})
export default class ResearchSpaceButton extends Vue {
    resource!: string;

    copyResource() {
        navigator.clipboard.writeText(this.resource);
    }
}
</script>
<style>
.rs-button-container {
    display: flex;
    flex-direction: column;
    width: 307px;
}
.rs-button-copy {
    display: flex;
    flex-direction: row;
}
.rs-button-resource {
    width: 100%;
    border: 1px solid #083943;
    border-bottom: none;
    border-radius: 5px 0px 0px 0px;
    padding: 5px;
}
.rs-button-copy-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    border: 1px solid #083943;
    border-left: none;
    border-bottom: none;
    border-radius: 0px 5px 0px 0px;
    cursor: pointer;
}
.rs-button-rs {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border: 1px solid #083943;
    border-radius: 0px 0px 5px 5px;
    text-decoration: none;
    color: #083943;
    margin-bottom: 15px;
}
.rs-button-rs img {
    margin-left: 5px;
}
.rs-button-hover {
    transition: 0.3s all ease;
}
.rs-button-hover:hover {
    background-color: #edf6f8;
    transition: 0.3s all ease;
}
</style>
