import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "annotation-info" }
const _hoisted_2 = { class: "annotation-title" }
const _hoisted_3 = {
  key: 0,
  class: "annotation-img-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { style: {"flex":"1"} }
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "annotation-card-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectAnnotation && _ctx.selectAnnotation(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.imageSrc != '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "annotation-img",
                src: _ctx.imageSrc
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.label), 1)
        ])
      ]),
      (_ctx.target != '')
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.target,
            target: "_blank"
          }, _toDisplayString(_ctx.target), 9, _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.dataset != '')
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.dataset), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}